<template>
  <div id="app">
    <div
      class="padding"
      :class="{ 'bg-white': location, 'padding-calc': location }"
      v-if="ismobile"
    ></div>
    <!-- header -->
    <div class="app-header" :class="{ 'bg-none': location }" v-if="ismobile">
      <van-nav-bar @click-right="handleShowShare">
        <template #left>
          <img
            class="back-btn"
            src="./assets/image/back.png"
            @click="handleBack"
            alt="返回按钮"
          />
        </template>
        <template #title>
          <span class="title">{{ title }}</span>
        </template>
        <template #right v-if="false">
          <img
            class="share-btn"
            src="./assets/image/share.png"
            alt="分享按钮"
          />
        </template>
      </van-nav-bar>
    </div>
    <div :class="{ 'app-body': !gradual, 'app-body-gradual': gradual }">
      <router-view />
    </div>

    <!-- 分享的效果 -->
    <van-share-sheet
      v-model="showShare"
      :options="options"
      title="分享至"
      @select="handleSelectShare"
    />

    <!-- 点击贷款利率的问号 -->
    <van-dialog
      v-model="$store.state.showRateModal"
      :show-confirm-button="false"
    >
      <div class="modal-content">
        <div style="overflow-y: scroll; height: 100%; overflow-x: hidden">
          <div class="content-item">
            <h2 class="content-item-title">1.贷款年限</h2>
            <p class="content-item-body">
              上限25年，与主贷人年龄、房屋建成年代（房龄）有关。比如：个别银行70减年龄，60减房龄，两者取低值为贷款年限（各个银行政策不一致，请以实际贷款银行为准）
            </p>
          </div>
          <div class="content-item">
            <h2 class="content-item-title">2.贷款利率</h2>
            <p class="content-item-body">2.1商贷利率＝LPR+基点</p>
            <p class="content-item-body">2.1.1LPR(贷款市场报价利率)</p>
            <p class="content-item-body">
              自2019年10月8日起，新发放的商业住房贷款的贷款利率由"贷款基准利率"转换为"贷款市场报价利率(LPR)”。在LPR的基础上增加基点来确定最终的商贷利率；LPR每月20日(遇节假日顺延)报价一次，可在中国人民银行网站查询。
            </p>
            <div style="text-align: center">
              <img src="./assets/image/tableLpr.png" width="200" alt="" />
            </div>
            <p class="content-item-body">2.1.2基点（浮动值BP）</p>
            <p class="content-item-body">
              自2019年10月8日起，商业贷款的计算方式由“贷款基准利率*上浮或下浮比例”改为”LPR+基点(1基点=0.01%)”
            </p>
            <p class="content-item-body">2.2公积金利率</p>
            <div style="text-align: center">
              <img src="./assets/image/table-gjj.png" width="200" alt="" />
            </div>
            <p class="content-item-body">
              北京地区现行政策：首套房贷的利率为LPR+55bp，二套房贷LPR+105bp
            </p>
          </div>
        </div>
        <van-button round block @click="closeRateModal" class="close-modal-btn"
          >关闭</van-button
        >
      </div>
    </van-dialog>

    <!-- 点击首付比例的问号 -->
    <van-dialog
      v-model="$store.state.showHouseModal"
      :show-confirm-button="false"
    >
      <div class="modal-content">
        <!-- 北京 -->
        <div
          v-if="$store.state.location.cityId === 1"
          style="overflow-y: scroll; height: 100%; overflow-x: hidden"
        >
          <div class="content-item">
            <h2 class="content-item-title">首付比例</h2>
            <p class="content-item-body">
              全国各城市对二套房的认定标准各不相同，具体以当地实际政策为准，如不清楚，请咨询门店经纪人。
            </p>
            <p class="content-item-body">
              特别说明：北京离婚一年以内购房按二套房执行，而按照3.17新政的规定，购买二套房的首付款比例不低于60%。
            </p>
          </div>
        </div>
        <!-- 上海 -->
        <div
          v-else-if="$store.state.location.cityId === 9"
          style="overflow-y: scroll; height: 100%; overflow-x: hidden"
        >
          <div class="content-item">
            <h2 class="content-item-title">首付比例</h2>
            <p class="content-item-body">①全国范围内无房无贷：算首套</p>
            <p class="content-item-body">②上海无房无贷，外地有房无贷：算首套</p>
            <p class="content-item-body">③上海有房无贷：算二套</p>
            <p class="content-item-body">④上海有房有贷：算二套</p>
            <p class="content-item-body">首套首付35%</p>
            <p class="content-item-body">二套</p>
            <p class="content-item-body">①普通住房：首付50%</p>
            <p class="content-item-body">②非普通住房：首付70%</p>
          </div>
        </div>
        <!-- 成都 -->
        <div
          v-else-if="$store.state.location.cityId === 15"
          style="overflow-y: scroll; height: 100%; overflow-x: hidden"
        >
          <div class="content-item">
            <h2 class="content-item-title">首付比例</h2>
            <p class="content-item-body">
              ① 天府新区+高新南区首套30%，二套70%，贷款最长年限25年
            </p>
            <p class="content-item-body">② 11区首套30%，二套60%，最长30年</p>
            <p class="content-item-body">③ 三圈层首套30%，二套50，最长30年</p>
            <p class="content-item-body">离异政策举例</p>
            <p class="content-item-body">
              ①婚前有夫妻双方有一套房产，共同还贷，房产证写男方，女方离异后购房算二套房
            </p>
            <p class="content-item-body">
              ②如果是男方婚前全款买房，夫妻离异后，女方购房还是算二套房%
            </p>
          </div>
        </div>
        <!-- 长沙 -->
        <div
          v-else-if="$store.state.location.cityId === 22"
          style="overflow-y: scroll; height: 100%; overflow-x: hidden"
        >
          <div class="content-item">
            <h2 class="content-item-title">首付比例</h2>
            <p class="content-item-body">
              ① 公积金贷款首套房最低20%，商业贷款最低30%。组合贷款最低30%.
            </p>
            <p class="content-item-body">
              ②
              二套房公积金贷款最低60%，商业贷款首套房贷已结清，首付最低35%，未结清，首付最低45%。
            </p>
          </div>
        </div>
        <!-- 南京 -->
        <div
          v-else-if="
            $store.state.location.cityId === 8 ||
            $store.state.location.cityId === 19
          "
          style="overflow-y: scroll; height: 100%; overflow-x: hidden"
        >
          <div class="content-item">
            <h2 class="content-item-title">首付比例</h2>
            <p class="content-item-body">
              ①
              首套房首付比例30%，二套房贷款已结清首付50%，贷款未结清首付80%，有两套及以上停贷。
            </p>
            <p class="content-item-body">
              ②
              限购政策加强，自夫妻离异之日起2年内购买商品住房，其拥有住房套数按照前家庭总套数计算。
            </p>
          </div>
        </div>
        <div
          v-else
          style="overflow-y: scroll; height: 100%; overflow-x: hidden"
        >
          <div class="content-item">
            <h2 class="content-item-title">首付比例</h2>
            <p class="content-item-body">
              青岛、杭州、苏州、太原、天津、南宁、郑州、武汉、南昌、常州、东莞、
              全国各城市对首套、二套房的认定标准各不相同，具体以当地实际政策为准，如不清楚，请咨询门店经纪人
            </p>
          </div>
        </div>
        <van-button round block @click="closeHouseModal" class="close-modal-btn"
          >关闭</van-button
        >
      </div>
    </van-dialog>

    <!-- 点击选择城市的问号 -->
    <van-dialog
      v-model="$store.state.showLocationModal"
      :show-confirm-button="false"
    >
      <div class="modal-content">
        <div class="content-item">
          <h2 class="content-item-title">城市规则</h2>
          <p class="content-item-body">
            1. 当首页选择了城市，此处带过来首页的城市。
          </p>
          <p class="content-item-body">
            2.
            假设首页选择北京，此处支持修改到其他城市比如南京，首页的城市不受影响。
          </p>
          <p class="content-item-body">
            3.
            不同城市首付比例不同，选择北京，首付比例栏，展示北京首套，二套比例，选择天津则展示天津的首付比例，首付比例处首套二套的值跟着城市改变。
          </p>
        </div>
        <van-button
          round
          block
          @click="closeLocationModal"
          class="close-modal-btn"
          >关闭</van-button
        >
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showShare: false,
      showRateModal: false,
      options: [
        { name: "微信", icon: require("./assets/image/wechat.png") },
        { name: "朋友圈", icon: require("./assets/image/moment.png") },
        { name: "QQ", icon: require("./assets/image/QQ.png") },
        // { name: "短信", icon: require("./assets/image/message.png") },
        // { name: "复制链接", icon: require("./assets/image/link.png") },
      ],
    };
  },
  created() {
    // 原生APP调用的jscallback
    window.jsCallBack = function () {
      return JSON.stringify({
        id: 0,
        url: "https://h.5i5j.com/calculator/#/",
        img_url: "https://rests.5i5j.com/wap/loan/images/calculator3x.png",
        title: "房贷计算器，一键算出怎么还",
        summary:
          "房贷计算器，一键算出怎么还专业房贷计算器，商业贷款、公积金贷款、组合贷款LPR央行利率",
      });
    };
  },
  methods: {
    handleShowShare() {
      this.showShare = true;
    },
    handleBack() {
      // TODO...
      let routeName = this.$route.name;
      this.$router.back(-1);
    },
    handleSelectShare(option) {
      // 点击分享
      this.$toast(`分享至${option.name}`);
      switch (option.name) {
        case "微信":
          // TODO
          break;
        case "朋友圈":
          // TODO
          break;
        case "QQ":
          // TODO
          break;
        case "短信":
          // TODO
          break;
        case "复制链接":
          // TODO
          break;
      }
      this.showShare = false;
    },
    toLocation() {
      if (this.$route.fullPath !== "/location") {
        this.$router.push("/location");
      }
    },
    closeRateModal() {
      this.$store.commit("showRateModal", false);
    },
    closeHouseModal() {
      this.$store.commit("showHouseModal", false);
    },
    closeLocationModal() {
      this.$store.commit("showLocationModal", false);
    },
  },
  computed: {
    title() {
      return this.$route.meta.title || "Page Not Found!";
    },
    close() {
      return this.$route.meta && this.$route.meta.close
        ? this.$route.meta.close
        : false;
    },
    gradual() {
      return this.$route.meta && this.$route.meta.gradual
        ? this.$route.meta.gradual
        : false;
    },
    location() {
      return this.$route.meta && this.$route.meta.location
        ? this.$route.meta.location
        : false;
    },
    locationCity() {
      return (
        (this.$store.state.location && this.$store.state.location.cityName) ||
        "北京"
      );
    },
    ismobile() {
      let userAgent = navigator.userAgent;
      let isAndroid =
        (userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1) &&
        userAgent.indexOf("from=app") > -1; //android终端
      let isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // console.log(ismobile, "ismobile");
      return !(isAndroid || isiOS);
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  background-color: #f4f4f4;
}
.padding {
  padding-top: 132px;
  background-color: #ffcc33;
}
.padding-calc {
  padding-top: 88px;
  background-color: #ffffff;
}
.bg-white {
  background-color: #ffffff;
}
.bg-none {
  background-image: none !important;
}
.app-body {
  background-image: url("./assets/image/bg-1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.app-body-gradual {
  background-image: linear-gradient(#ffcc33, #f4f4f4);
}
.app-header {
  background-image: url("./assets/image/header.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
}
.app-header /deep/ .van-nav-bar {
  background-color: transparent;
}
.app-header /deep/ .van-nav-bar__content {
  height: 88px;
}
#app /deep/ .van-share-sheet__icon {
  margin: 0 0.3rem;
}
#app /deep/ .van-share-sheet__options {
  justify-content: space-around;
}
.close-btn,
.share-btn,
.back-btn {
  width: 45px;
  height: 45px;
}
.title {
  font-size: 36px;
  font-weight: 500;
  color: #292b33;
  line-height: 36px;
}
.location {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.location span {
  color: #333333;
  font-size: 32px;
  font-weight: 500;
}
.location .down-icon {
  color: #333333 !important;
  font-weight: 500;
}

#app /deep/ .van-dialog {
  overflow: inherit;
}
.modal-content {
  padding: 64px 50px;
  background-color: #ffffff;
  border-radius: 16px;
  height: 843px;
  box-sizing: border-box;
  padding-bottom: 160px;
  position: relative;
}
.modal-content .content-item {
  margin-bottom: 40px;
}
.modal-content .content-item-title {
  color: #292b33;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 20px;
}
.modal-content .content-item-body {
  color: #333;
  font-size: 26px;
  line-height: 36px;
  text-align: justify;
}
.close-modal-btn {
  position: absolute !important;
  margin: auto !important;
  width: 80% !important;
  left: 0;
  right: 0;
  bottom: 40px;
  background-color: #ffcc33 !important;
}
</style>
